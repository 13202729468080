import * as types from "./types";
import _ from "lodash";
export default (state = null, action) => {
  switch (action.type) {
    case types.FETCH_MASTER_DDLS:
      // return { ...state, ..._.mapKeys(action.payload, "Id") };
      return action.payload;
    // return { ...state, [action.payload]: action.payload };
    default:
      return state;
  }
};
export const EmployeesDDL = (state = {}, action) => {
  switch (action.type) {
    case types.EMPLOYEES_DDLS:
      return action.payload;
    default:
      return state;
  }
};
export const RequestStatus = (state = {}, action) => {
  switch (action.type) {
    case types.FETCH_REQUEST_STATUS:
      return { ..._.mapKeys(action.payload, "value") };
    default:
      return state;
  }
};
export const DepartmentDDL = (state = [], action) => {
  switch (action.type) {
    case types.FETCH_DEPARTMENT_DDL:
      return { ..._.mapKeys(action.payload, "value") };
    default:
      return state;
  }
};
export const MasterAssetDDL = (state = [], action) => {
  switch (action.type) {
    case types.FETCH_MASTER_ASSET_DDL:
      return { ..._.mapKeys(action.payload, "value") };
    default:
      return state;
  }
};
export const PayrollColumnsList = (state = [], action) => {
  switch (action.type) {
    case types.FETCH_PAYROLL_COLUMN_LIST:
      return { ..._.mapKeys(action.payload, "value") };
    default:
      return state;
  }
};
export const EmployeeDependentsDDL = (state = [], action) => {
  switch (action.type) {
    case types.FETCH_EMPLOYEE_DEPENDENT:
      return { ..._.mapKeys(action.payload, "value") };
    default:
      return state;
  }
};
export const EmployeeCheckListTypeDDL = (state = [], action) => {
  switch (action.type) {
    case types.FETCH_EMPLOYEE_CHECK_LIST_DDL:
      return { ..._.mapKeys(action.payload, "value") };
    default:
      return state;
  }
};
export const MasterVehicleTypeDDL = (state = [], action) => {
  switch (action.type) {
    case types.FETCH_MASTER_VEHICLE_TYPE_DDL:
      return { ..._.mapKeys(action.payload, "value") };
    default:
      return state;
  }
};
